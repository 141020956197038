import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
export default {
  name: "profile",
  components:{
  },
  data(){
    return{

    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
    }),
  },

  methods:{
    ...mapMutations({
    }),
    ...mapActions({
    }),
  }
}